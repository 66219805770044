import api from "./api/api";

export default {
  namespaced: true,
  state: {
    contests: [],
  },
  getters: {
    all: state => {
      return state.contests
    },
    byType: (state) => (type) => {
      return state.contests.find(contest => contest.type === type)
    },
    allCategorized: state => {
      let contests = {}
      for (const contest of state.contests) {
        if (!contests[contest.category]) {
          contests[contest.category] = []
        }
        contests[contest.category].push(exhibitor)
      }
      return contests
    },
    featured: state => {
      return state.contests.filter(contest => contest.featured === true)
    }
  },
  mutations: {
    UPDATE_CONTESTS(state, value) {
      state.contests = value
    },
  },
  actions: {
    clear({commit}) {
      commit('UPDATE_CONTESTS', [])
    },
    getContests({commit, state, rootState}, eventSlug) {
      commit('UPDATE_CONTESTS', 
      [
        {
          "id": "contest-2",
          "category": "engagement",
          "title": "Three Most Engaged Participants",
          "banner": "",
          "shortDescription": "",
          "description":"<p><strong>Contest Rules</strong></p><ul><li>Ask and answer questions and take the polls, put comments, attend sessions, visit booths</li><li>The top three engaged participants will get exciting prizes and shout out on TalentNomics Linkedin Page and Instagram</li><li>Winners will be announced at the end of Day 2, Dec 10th</li><li>TN team will reach out to you for your details.</li></ul>",
        },
        {
          "id": "contest-3",
          "category": "social",
          "title": "Three Top Participants Who Promote The Event Through Social Media",
          "banner": "",
          "shortDescription": "",
          "description":"<p><strong>Contest Rules</strong></p><ul><li>Follow & Tag our Social Media Handles on LinkedIn, Instagram, Facebook and Twitter</li><li>Post live pictures of the Conference and tag TalentNomics India and add the hashtag <strong>(#WeStandForEquiverse)</strong></li><li>The top three participants with the most engagement on Social Media will get exciting prizes and a shout-out on our Social Media.</li></ul>",
        },
        {
          "id": "contest-1",
          "category": "survey",
          "title": "Depiction of an Equiverse",
          "banner": "",
          "shortDescription": "",
          "description":"<p>Answer two questions on your thoughts on a gender-equal world.</p><p><strong>Responses to be submitted by participants before 10th Dec 2021 2:30pm IST</strong></p>",
        },
      ])
    },
  },
}
