import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/home', redirect: { name: 'home' } },
    {
      path: '/',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Home.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/pages/authentication/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/explore',
      name: 'explore',
      component: () => import('@/views/pages/authentication/Explore.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth'
      },
    },
    {
      path: '/reception',
      name: 'reception',
      component: () => import('@/views/pages/reception/Reception.vue'),
    },
    {
      path: '/stage',
      name: 'stage',
      component: () => import('@/views/pages/stage/Stage.vue'),
    },
    {
      path: '/stage/:session',
      name: 'stage-session',
      component: () => import('@/views/pages/stage/Stage.vue'),
      meta: {
        navActiveLink: 'stage',
      },
    },
    {
      path: '/awards',
      name: 'stage2',
      component: () => import('@/views/pages/stage/Stage2.vue'),
    },
    {
      path: '/sessions',
      name: 'sessions',
      component: () => import('@/views/pages/sessions/Sessions.vue'),
    },
    {
      path: '/exhibition',
      name: 'exhibition',
      component: () => import('@/views/pages/exhibition/Exhibition.vue'),
    },
    {
      path: '/exhibition/:slug',
      name: 'exhibitor-detail',
      component: () => import('@/views/pages/exhibition/ExhibitorDetail.vue'),
      meta: {
        navActiveLink: 'exhibition',
      },
    },
    {
      path: '/speakers',
      name: 'speakers',
      component: () => import('@/views/pages/participants/Participants.vue'),
      meta: {
        type: 'speaker'
      }
    },
    {
      path: '/attendees',
      name: 'attendees',
      component: () => import('@/views/pages/participants/Participants.vue'),
      meta: {
        type: 'attendee'
      }
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/pages/partners/Partner.vue'),
    },
    {
      path: '/contests',
      name: 'contests',
      component: () => import('@/views/pages/contests/Contests.vue'),
    },
    {
      path: '/chat',
      name: 'apps-chat',
      component: () => import('@/views/apps/chat/Chat.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },
    {
      path: '/profile',
      name: 'user-profile',
      component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    },
    {
      path: '/pages/miscellaneous/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/:slug',
      name: 'auth-login-event',
      component: () => import('@/views/pages/authentication/Home.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/e/:slug',
      name: 'auth-login-event-alt',
      component: () => import('@/views/pages/authentication/Home.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      name: 'home-catchall',
      component: () => import('@/views/pages/authentication/Home.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
  ],
})

router.beforeEach((to, _, next) => {
  // const isLoggedIn = isUserLoggedIn()
  const isLoggedIn = store.getters['account/isUserLoggedIn']
  // console.log("store.getters.account", store.getters['account/isUserLoggedIn'], isLoggedIn, to);
  if (to.name !== 'auth-login' && to.name !== 'auth-login-event' && to.name !== 'login' && to.name !== 'register') {
    // console.log("redirecting to auth-login");
    if (!isLoggedIn) return next({ name: 'auth-login' })
  } 
  
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   console.log('not canNavigate(to), checking isLoggedin')
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   console.log('not-autho')
  //   return next({ name: 'misc-not-authorized' })
  // } else {
  //   console.log('canNavigate')
  // }

  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   // console.log('redirecting to reception')
  //   // const userData = store.account.getUserData()
  //   // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  //   const userRoles = store.getters['account/getRoles']
  //   next(getHomeRouteForLoggedInUser(userRoles ? userRoles.type : null))
  //   // next({ name: 'reception' })
  // }
  // console.log('returning next')
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
