import api from "./api/api";

export default {
  namespaced: true,
  state: {
    exhibitors: [],
  },
  getters: {
    all: state => {
      return state.exhibitors
    },
    bySlug: (state) => (slug) => {
      return state.exhibitors.find(exhibitor => exhibitor.slug === slug)
    },
    allCategorized: state => {
      let exhibitors = {}
      for (const exhibitor of state.exhibitors) {
        if (!exhibitors[exhibitor.category]) {
          exhibitors[exhibitor.category] = []
        }
        exhibitors[exhibitor.category].push(exhibitor)
      }
      return exhibitors
    },
    featured: state => {
      return state.exhibitors.filter(exhibitor => exhibitor.featured === true)
    }
  },
  mutations: {
    UPDATE_EXHIBITORS(state, value) {
      state.exhibitors = value
    },
  },
  actions: {
    clear({commit}) {
      commit('UPDATE_EXHIBITORS', [])
    },
    dropcard(context, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api.post('eventcarddrops', payload)
          if (response.data && !response.data.error) {
            resolve(response.data)
          } else {
            reject(response.data.error)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    getExhibitors({commit, state, rootState}, eventSlug) {
      // console.log('eventSlug', eventSlug)
      if (eventSlug) {
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
      } 
      return new Promise(async (resolve, reject) => {
        try {
          // let slug = eventSlug || rootState.event.eventSlug || (apiConfig.hostname === 'localhost' ? '3kpuQuZV293IeL1iVKiuEh' : apiConfig.hostname)
          let slug = eventSlug || rootState.event.eventSlug || apiConfig.hostname
          // const response = await api.get(`/events/list/${apiConfig.hostname}`);
          const response = await api.get(`/events/exhibits/${slug}`);
          if (response.data) {
            commit('UPDATE_EXHIBITORS', response.data)
            resolve(response.data)
          } else {
            commit('UPDATE_EXHIBITORS', [])
            resolve([])
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  },
}
