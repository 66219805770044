import api from "./api/api";
import apiConfig from "./api/apiConfig"

export default {
  namespaced: true,
  state: {
    partners: [
    ],
  },
  getters: {
    all: state => {
      return state.partners
    },
    featured: state => {
      return state.partners.filter(partner => partner.featured === true)
    },
    byPage: state => (page) => {
      return state.partners.filter(partner => partner.displayPages[page] === true)
    },
  },
  mutations: {
    UPDATE_PARTNERS(state, value) {
      state.partners = value
    },
  },
  actions: {
    clear({commit}) {
      commit('UPDATE_PARTNERS', [])
    },
    getPartners({commit, state, rootState}, eventSlug) {
      // console.log('eventSlug', eventSlug)
      if (eventSlug) {
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
      } 
      return new Promise(async (resolve, reject) => {
        try {
          // let slug = eventSlug || rootState.event.eventSlug || (apiConfig.hostname === 'localhost' ? '3kpuQuZV293IeL1iVKiuEh' : apiConfig.hostname)
          let slug = eventSlug || rootState.event.eventSlug || apiConfig.hostname
          // const response = await api.get(`/events/list/${apiConfig.hostname}`);
          const response = await api.get(`/events/partners/${slug}`);
          if (response.data) {
            commit('UPDATE_PARTNERS', response.data)
            resolve(response.data)
          } else {
            commit('UPDATE_PARTNERS', [])
            resolve([])
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  },
}
