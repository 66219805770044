const hostname = window.location.hostname
const isProduction = (hostname === 'localhost' ? false : hostname === 'staging.hummz.app' ? false : true)
// const isProduction = true;
const socketUrl = isProduction ? 'wss://fzmyeq0n84.execute-api.ap-south-1.amazonaws.com/production' : 'wss://fzmyeq0n84.execute-api.ap-south-1.amazonaws.com/dev';
const connectSocket = isProduction && hostname !== 'localhost'
const baseUrl = isProduction ? 'https://api.hummz.net/v2' : (hostname === 'localhost' ? 'http://localhost:3000/v2' : hostname === 'staging.hummz.app' ? 'https://apidev.hummz.net/v2' : 'https://api.hummz.net/v2')
// const baseUrl = 'https://api.hummz.net/v2'

export default {
    hostname,
    isProduction,
    socketUrl,
    baseUrl,
}