const availableNavigation = [
    {
        title: 'Reception',
        route: 'reception',
        icon: 'HomeIcon',
    },
    {
        title: 'Stage',
        route: 'stage',
        icon: 'VideoIcon',
    },
    {
        title: 'Sessions',
        route: 'sessions',
        icon: 'CalendarIcon',
    },
    {
        title: 'Speakers',
        route: 'speakers',
        icon: 'UsersIcon',
    },
    {
        title: 'Exhibition',
        route: 'exhibition',
        icon: 'GridIcon',
    },
    {
        title: 'Partners',
        route: 'partners',
        icon: 'ApertureIcon',
    },
    {
        title: 'Attendees',
        route: 'attendees',
        icon: 'UsersIcon',
    },
    {
        title: 'Contests',
        route: 'contests',
        icon: 'AwardIcon',
    },
    // {
    //   title: 'Networking',
    //   route: 'apps-chat',
    //   icon: 'MessageCircleIcon',
    // },
]

export default {
    namespaced: true,
    state: {
        navigation: []
    },
    getters: {
        all: state => {
            return state.navigation
        },
        getTitle: state => (route) => {
            let title = ''
            for (const nav of state.navigation) {
                if (nav.route === route) {
                    title = nav.title
                    break
                }
            }
            return title
        }
    },
    mutations: {
        UPDATE_NAVIGATION(state, value) {
            // state.attendees = value.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
            state.navigation = value
        },
    },
    actions: {
        clear({ commit }) {
            commit('UPDATE_NAVIGATION', [])
        },
        getNavigation({ commit, state, rootState }) {
            let eventNavigation = rootState.event.event.meta.navigation || availableNavigation
            // console.log('eventNavigation', eventNavigation)
            return new Promise(async (resolve, reject) => {
                try {
                    // console.log('navigations', response)
                    commit('UPDATE_NAVIGATION', eventNavigation)
                    resolve(eventNavigation)
                } catch (error) {
                    reject(error)
                }
            })
        }
    },
}
