export const initialAbility = [
  // {
  //   action: 'manage',
  //   subject: 'all',
  // },
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const _ = undefined
