import Vue from 'vue'
import axios from 'axios'
import apiConfig from "./apiConfig";

let axiosOptions = {
    baseURL: apiConfig.baseUrl,
    headers: { 'Content-Type': 'application/json', },
}
const api = axios.create(axiosOptions)

Vue.prototype.$http = api

export default api
