import Vue from 'vue'
import store from '@/store'
import VueNativeSock from 'vue-native-websocket'
import apiConfig from '@/store/api/apiConfig';

// Websocket
if (apiConfig.connectSocket) {
  Vue.use(VueNativeSock, apiConfig.socketUrl, {format: 'json', reconnection: true, reconnectionDelay: 1000, store: store})
}
  // let vm = new Vue()
  //   vm.$options.sockets.onopen = (data) => {
  //     console.log('socket', data);
  //   }