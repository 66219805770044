<template>
    <b-sidebar id='participant-details' v-model="showParticipantSidebar" @hidden="onHide()" width="30rem" lazy right shadow backdrop no-header sidebar-class="border-left border-primary">
      <b-button variant="light-primary" style="position:absolute;top:2px;" v-b-toggle.participant-details><font-awesome-icon :icon="['fal', 'times-circle']" size="2x" class="text-primary" /></b-button>
      <ParticipantDetail :key="participantKey" :data="participantData" :enableChat="enableChat" :enableCall="enableCall" :enableMeeting="enableMeeting" />
    </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BButton,
} from "bootstrap-vue";
import ParticipantDetail from './ParticipantDetail.vue';
import store from '@/store/index'

export default {
  components: {
    BSidebar,
    BButton,
    ParticipantDetail,
  },
  data() {
    return {
      participantKey: '',
      showParticipantSidebar: false,
      participantData: {}
    }
  },
  watch: {
    getSidebarShow(value) {
      // console.log('getSidebarShow', value)
      this.showParticipantSidebar = value
    },
    getSidebarData(value) {
      // console.log('getSidebarData', value)
      this.participantData = value
    },
  },
  computed: {
    getSidebarShow() {
      return store.getters['attendees/getSidebarShow']
    },
    getSidebarData() {
      return store.getters['attendees/getSidebarData']
    },
    enableChat() {
      return this.$store.state.attendees.sidebarChat
    },
    enableCall() {
      return this.$store.state.attendees.sidebarCall
    },
    enableMeeting() {
      return this.$store.state.attendees.sidebarMeeting
    }
  },
  methods: {
    onHide() {
      store.dispatch('attendees/hideSidebar')
    }
  }
};
</script>

<style>
  
</style>
