import dayjs from "@/libs/dayjs";
import api from "./api/api";
import apiConfig from "./api/apiConfig"

export default {
  namespaced: true,
  state: {
    sessions: [],
    stageStatus: {},
    playerIsMuted: false,
  },
  getters: {
    all: state => {
      return state.sessions
    },
    allDated: state => {
      let sessions = {}
      for (const session of state.sessions) {
        if (!sessions[dayjs(session.scheduledStart).format('YYYY-MM-DD')]) {
          sessions[dayjs(session.scheduledStart).format('YYYY-MM-DD')] = []
        }
        sessions[dayjs(session.scheduledStart).format('YYYY-MM-DD')].push(session)
      }
      return sessions
    },
    featured: state => {
      return state.sessions.filter(session => session.featured === true)
    },
    byId: state => (slug) => {
      // return the next session
      let _session = state.sessions.find(session => session.slug === slug)
      return _session
    },
    currentSession: state => (stage) => {
      // return the next session
      let _sessions = state.sessions.filter(session => dayjs().isBetween(dayjs(session.scheduledStart), dayjs(session.scheduledEnd), null, []))
      if (stage && stage.length > 0) {
        return _sessions.find(session => session.location === stage)
      }
      return _sessions
    },
    sessionPolls: state => (slug) => {
      // return the next session
      const _session = state.sessions.find(session => session.slug === slug)
      let _polls = []
      if (_session.polls && Array.isArray(_session.polls)) {
        _polls = _session.polls
      }
      return _polls
    },
    liveSessions: state => (stage) => {
      // return all live sessions
      let _sessions = state.sessions.filter(session => dayjs().isBetween(dayjs(session.scheduledStart), dayjs(session.scheduledEnd), null, []))
      if (stage && stage.length > 0) {
        return _sessions.find(session => session.location === stage)
      }
      return _sessions
    },
    upcomingSession: state => (stage) => {
      // return the next session
      let _session = state.sessions.find(session => (dayjs(session.scheduledStart).isAfter(dayjs()) && session.location === stage))
      return _session
    },
    upcomingSessionsToday: state => {
      // return the next session
      let _sessions = state.sessions.filter(session => (dayjs(session.scheduledStart).isToday() && dayjs(session.scheduledStart).isAfter(dayjs())) )
      return _sessions
    },
    sessionPartners: (state, getters, rootState) => (slug) => {
      // console.log('sessionPartners', slug)
      const _partners = rootState.partners.partners
      // console.log('sessionPartners', _partners)
      const _session = state.sessions.find(session => session.slug === slug)
      // console.log('sessionPartners', _session)
      let sessionPartners = []
      if (_session.sponsors && Array.isArray(_session.sponsors) && _session.sponsors.length > 0) {
        for (const sponsor of _session.sponsors) {
          const partner = _partners.find(partner => partner.slug === sponsor)
          sessionPartners.push(partner)
        }
      }
      return sessionPartners
    }
  },
  mutations: {
    UPDATE_SESSIONS(state, value) {
      state.sessions = value
    },
    UPDATE_STAGE_STATUS(state, value) {
      state.stageStatus = value
    },
    UPDATE_PLAYER_ISMUTED(state, value) {
      state.playerIsMuted = value
    },
  },
  actions: {
    clear({commit}) {
      commit('UPDATE_SESSIONS', [])
    },
    getSessions({commit, dispatch, rootState}, eventSlug) {
      // console.log('eventSlug', eventSlug)
      if (eventSlug) {
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
      } 
      return new Promise(async (resolve, reject) => {
        try {
          // let slug = eventSlug || rootState.event.eventSlug || (apiConfig.hostname === 'localhost' ? '3kpuQuZV293IeL1iVKiuEh' : apiConfig.hostname)
          let slug = eventSlug || rootState.event.eventSlug || apiConfig.hostname
          const response = await api.get(`/events/sessions/${slug}`);
          if (response.data) {
            commit('UPDATE_SESSIONS', response.data)
            resolve(response.data)
          } else {
            commit('UPDATE_SESSIONS', [])
            resolve([])
          }
          dispatch('updateCurrentSessions')
        } catch (error) {
          reject(error)
        }
      })
    },
    setPlayerMuted({commit}, isMuted) {
      commit('UPDATE_PLAYER_ISMUTED', isMuted)
    },
    setSessionLive({commit, state}, sessionId) {
      commit('UPDATE_LIVE_SESSIONS', [...state.liveSessions, sessionId])
    },
    setSessionEnded({commit, state}, sessionId) {
      let sessions = state.liveSessions.filter(session => session.id !== sessionId)
      commit('UPDATE_LIVE_SESSIONS', sessions)
    },
    updateCurrentSessions({commit, state}) {
      let _sessions = state.sessions.filter(session => dayjs().isBetween(dayjs(session.scheduledStart), dayjs(session.scheduledEnd), null, []))
      let _stageStatus = {}
      for(const session of _sessions) {
        if (!_stageStatus[session.location]) {
          _stageStatus[session.location] = {}
        }
        _stageStatus[session.location].currentSession = session
      }
      _sessions = state.sessions.filter(session => dayjs().isBetween(dayjs(session.scheduledStart), dayjs(session.scheduledEnd), null, []))
      for(const session of _sessions) {
        if (!_stageStatus[session.location]) {
          _stageStatus[session.location] = {}
        }
        _stageStatus[session.location].liveSession = session
      }
      _sessions = state.sessions.find(session => (dayjs(session.scheduledStart).isAfter(dayjs())))
      if (_sessions) {
        // console.log('_sessions', _sessions);
        if (!_stageStatus[_sessions.location]) {
          _stageStatus[_sessions.location] = {}
        }
        _stageStatus[_sessions.location].upcomingSession = _sessions
      } 
      // console.log('updateCurrentSessions _stageStatus', _stageStatus);
      commit('UPDATE_STAGE_STATUS', _stageStatus)
    },
    trackEvent({rootState}, eventData) {
      // console.log('trackEvent', rootState.account.user.bookingId)
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api.post(`/stats`, {stat: eventData, statableType: 'booking', statableId: rootState.account.user.bookingId });
          if (response.data) {
            resolve(response.data)
          } else {
            resolve({})
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  },
}
