import Vue from 'vue'
import posthog from 'posthog-js'
// "@sentry/tracing": "^7.114.0",
// "@sentry/vue": "^8.20.0",
// import * as Sentry from "@sentry/vue";
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import Date from '@/libs/date'
import router from './router'
import store from './store'
import App from './App.vue'
import VueMeta from 'vue-meta'

// Global Components
import './global-components'

// 3rd party plugins
// import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/tour'
import '@/libs/websocket'

const isProduction = (window.location.hostname === 'localhost' ? false : true)

// Axios Mock Adapter
// import '@/@fake-db/db'

// DayJs Plugin
Vue.use(Date);

// BSV Plugin Registration
Vue.use(BootstrapVue, {
  breakpoints: [`xs`, 'sm', 'md', 'lg', 'xl', 'xxl']
})
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Vue Meta
Vue.use(VueMeta, {refreshOnceOnNavigation: true})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
// if (isProduction) {
  posthog.init('phc_1nJQsl8a4cPjnAyCwsXsuKa7LptDyTM0q4q8GAhJGyR', { api_host: 'https://app.posthog.com' })
  // Sentry.init({
  //   Vue,
  //   dsn: "https://88816ec17a654acd906b3daae29306e9@o997173.ingest.sentry.io/6099399",
  //   integrations: [new posthog.SentryIntegration({organization: 'weapptivate-link-technologies-', projectId: 6099399})],
  //   environment: isProduction ? 'production' : 'development',

  //   // integrations: [
  //   //   new Integrations.BrowserTracing({
  //   //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //   //     tracingOrigins: ["localhost", "my-site-url.com", /^\//],
  //   //   }),
  //   // ],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
// }

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
