import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persist-indexeddb";
import createPersistedState from "vuex-persistedstate";
import { EncryptStorage } from 'encrypt-storage';

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import socket from './socket';
import account from './account'
import event from './event'
import navigation from './navigation';
import notifications from './notifications';
import messages from './messages';
import sessions from './sessions'
import attendees from './attendees'
import exhibitors from './exhibitors'
import partners from './partners'
import contests from './contests'

Vue.use(Vuex)

const encryptStorage = new EncryptStorage('AjcsS781bK13cd1UhnBtN58tnkGAGD3fmXV87qw4jv37JjpoW6d7kNbWB5PUqc0HLMY0DS40HUqmWiBRMAUAtB188cxHacr4gA2ZXTMkuJjAOfJXpXRlyUDfHf8w756iGvuI4V5o0IFigl5PwV75tOuiZzEIeRTWlj1vMvDXTHQFBxhnT36UlMAICXl7ZwziSOweD7tFmi03zLq9HJ3I4XDrGHhPU8ZuJkHdhky49eEpHK5CFccuGe1aBunVB2eGEiiFRVd1AzIkdj2eXbEZf0ysXZSbJrXcZwWbnVadIMNqd0jZCwB6Ta7zbXNuurluRRUMxxe1kXpv0UtAYXeWRgpgqPOJoiuR1OQiQ3GMIIRlztFTQ9RXJUeKCN4Z7usPTEpUuXQbTfIKlxw8KRNoX3bigOGuRYS2WFk3exCVIognwNM4xC8wDjzUvrlrWgZzIpKmm5JRCU53ZfSTTshaKJwJXfgGcQyhZzvFpALeMkR5uofi2ICd1vohRWROZnWus7W4UIC1nUhHw2UaXqOt7qKqKUaU3KZDjPQ7OEDtT3TviXXEqIzL4e3PnEdDlfclOLFSMJLTGGiF7LXwPAcjAzW44ctGshenmkSeMZNaLjlifgoARL1KuspW0bO0zNEL', {prefix: '@hmze',storageType: "localStorage", "stateManagementUse": true}); 

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    socket,
    account,
    event,
    navigation,
    notifications,
    messages,
    sessions,
    attendees,
    exhibitors,
    partners,
    contests,
  },
  plugins: [createPersistedState({
    key: "hmze2",
    storage: {
          getItem: key => encryptStorage.getItem(key),
          setItem: (key, value) => encryptStorage.setItem(key, value),
          removeItem: key => encryptStorage.removeItem(key)
        }
  })],
  strict: process.env.DEV,
})
// {
//   storage: {
//     getItem: key => encryptStorage.getItem(key),
//     setItem: (key, value) => encryptStorage.setItem(key, value),
//     removeItem: key => encryptStorage.removeItem(key)
//   }
// }
