<template>
  <div class="d-flex flex-column">
    <div class="p-2 bg-light-primary d-flex flex-row justify-content-center">
      <div class="d-block text-center mb-2" v-if="!data.avatar">
        <avatar variant="bauhaus" :size="100" :colors="['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']" :name="data.name || data.firstname + ' ' + data.middlename + ' ' + data.lastname" />
      </div>
      <b-avatar
        v-else
        size="12rem"
        class="border-primary shadow-lg"
        :text="avatarText(data.name || data.firstname + ' ' + data.middlename + ' ' + data.lastname)"
        :src="data.avatar"
        variant="light-primary"
      />
      <!-- <div class="ml-2">
          <h2>{{ data.title }} {{ data.firstname }} {{ data.middlename }} {{ data.lastname }}</h2>
          <h4>{{ data.description }}<br/>{{ data.designation }}<br/>{{ data.organization }}</h4>
      </div> -->
    </div>
    <div v-if="chat" class="px-2 pb-1 bg-light-primary d-flex flex-row justify-content-center">
        <b-button variant="primary" class="mr-1">Chat</b-button>
    </div>
    <div
      v-if="data"
      class="m-0 p-1"
      flex-grow-1
    >
      <div>
        <h2>{{ data.name || data.firstname + ' ' + data.middlename + ' ' + data.lastname}}</h2>
        <h4>
            <span v-if="data.description" class="font-weight-bold">{{ data.description }}</span>
            <span v-else class="font-weight-bold">{{ data.designation }}<br/>{{ data.organization }}</span>
        </h4>
        <div class="my-1" v-if="data.social">
          <b-link v-for="socialLink in socialLinks" :key="socialLink" :href="data.social[socialLink]" target="_blank">
            <font-awesome-icon v-if="socialLink !== 'website'" :icon="['fab', socialLink]" size="lg" class="mr-1" />
            <font-awesome-icon v-if="socialLink === 'website'" :icon="['fal', 'globe']" size="lg" class="mr-1" />
          </b-link>
        </div>
      </div>
      <div v-html="data.profile" />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BLink, BButton, BAvatar, BCollapse, VBToggle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Avatar from 'vue2-boring-avatars'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BLink,
    BButton,
    BAvatar,
    BCollapse,
    Avatar
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      avatarText,
      profileVisible: true,
    }
  },
  computed: {
    socialLinks() {
      return Object.keys(this.data.social).filter(link => this.data.social[link] !== '')
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    chat: {
      type: Boolean,
      default: () => false,
    },
    call: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
