import api from "./api/api";
import apiConfig from "./api/apiConfig"

export default {
  namespaced: true,
  state: {
    messages: [],
    chatInputMessage: ''
  },
  getters: {
    all: state => {
      return state.messages
    },
    byChannel: state => (channelId) => {
      return state.messages.filter(message => message.channelId === channelId)
    },
    byParticipant: state => (participantId) => {
      return state.messages.filter(message => (message.fromId === participantId || message.toId === participantId))
    },
  },
  mutations: {
    UPDATE_CHAT_INPUT_MESSAGE(state, value) {
      state.chatInputMessage = value
    },
    UPDATE_MESSAGES(state, value) {
      state.messages = value
    },
  },
  actions: {
    clearMessages({ commit }) {
      // console.log('clearing messages');
      commit('UPDATE_MESSAGES', [])
    },
    updateChatInputMessage({commit}, message) {
      commit('UPDATE_CHAT_INPUT_MESSAGE', message)
    },
    updateMessages({
      commit, state
    }, messages) {
      let _messages = state.messages
      for (const m of messages) {
        if (!_messages.find(message => message.id === m.id)) {
          _messages.push(m)
        }
      }
      commit('UPDATE_MESSAGES', _messages)
    },
    pushMessage({
      commit,
      state
    }, message) {
      let _messages = state.messages
      if (!_messages.find(m => m.id === message.id)) {
        _messages.push(message)
      }
      commit('UPDATE_MESSAGES', _messages)
    }
  },
}