import api from "./api/api";
import apiConfig from "./api/apiConfig"

export default {
  namespaced: true,
  state: {
    attendees: [],
    sidebarData: {},
    sidebarShow: false,
    sidebarChat: false,
    sidebarCall: false,
    sidebarMeeting: false,
  },
  getters: {
    all: state => {
      return state.attendees
    },
    getSidebarShow: (state) => {
      // console.log('attendees:gettet:getSidebarShow')
      return state.sidebarShow
    },
    getSidebarData: (state) => {
      // console.log('attendees:gettet:getSidebarData')
      return state.sidebarData
    },
    byType: (state) => (attendeeType, filter) => {
      if (attendeeType === 'attendee') {
        attendeeType = 'delegate'
      }
      filter = filter.toLowerCase()
      // console.log('filter', filter);
      let _attendees = state.attendees.filter(attendee => {
        // console.log('attendee', attendee)
        if (filter != '') {
          if (JSON.stringify(attendee).toLowerCase().indexOf(filter)<0) {
            return false
          } else {
            // console.log('filter matched', attendee)
          }
        }
        if (attendee.tickets.length === 0) {
          return false
        } else {
          return attendee.tickets[0].type === attendeeType
        }
      })
      return _attendees.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
      // return state.attendees.filter(attendee => {return attendee.tickets.every(ticket => ticket.type === attendeeType)})
    },
    byTypeCategorized: (state, getters, rootState) => (attendeeType, filter) => {
      const speakerCategories = rootState.event.event.meta.speakerCategories || []
        if (attendeeType === 'attendee') {
          attendeeType = 'delegate'
        }
        filter = filter.toLowerCase()
        // console.log('filter', filter);
        let _attendees = state.attendees.filter(attendee => {
          // console.log('attendee', attendee)
          if (filter != '') {
            if (JSON.stringify(attendee).toLowerCase().indexOf(filter)<0) {
              return false
            } else {
              // console.log('filter matched', attendee)
            }
          }
          if (attendee.tickets.length === 0) {
            return false
          } else {
            return attendee.tickets[0].type === attendeeType
          }
        })
        // _attendees = _attendees.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
        _attendees = _attendees.sort((a,b) => {
          if (a.displayOrder && b.displayOrder) {
            return a.displayOrder - b.displayOrder
          } else {
            return b.name.toUpperCase().localeCompare(a.name.toUpperCase())
          }
        })
        let categorisedAttendees = _attendees.reduce(function(rv, x) {
          (rv[x['label']] = rv[x['label']] || []).push(x);
          return rv;
        }, {});
        // console.log('categorisedAttendees', categorisedAttendees)
        let orderedCategories = {}
        for (const category of speakerCategories) {
          // console.log('processing ', category)
          orderedCategories[category] = categorisedAttendees[category] || []
        }
        // console.log('orderedCategories', orderedCategories)
        return orderedCategories
        // return state.attendees.filter(attendee => {return attendee.tickets.every(ticket => ticket.type === attendeeType)})
    },
    bySlug: (state) => (slug) => {
      return state.attendees.filter(attendee => attendee.rid === slug)
    },
    byId: (state) => (id) => {
      return state.attendees.filter(attendee => attendee.id === id)
    },
    byBookingNumber: (state) => (bookingNumber) => {
      return state.attendees.filter(attendee => attendee.bookingNumber === bookingNumber)
    },
    sessionSpeakers: (state) => (speakerIds) => {
      let ids = speakerIds.split(',')
      // console.log('filter', filter);
      return state.attendees.filter(attendee => {
        // console.log('attendee', attendee)
        if (attendee.tickets.length === 0) {
          return false
        } else {
          return (['speaker', 'emcee'].indexOf(attendee.tickets[0].type)>=0 && ids.indexOf(attendee.id)>=0)
        }
      })
    }
  },
  mutations: {
    UPDATE_ATTENDEES(state, value) {
      // state.attendees = value.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
      // console.log('UPDATE_ATTENDEES', value)
      state.attendees = value.sort((a,b) => {
        const a_name = a.name || a.firstname + ' ' + a.middlename + ' ' + a.lastname;
        const b_name = b.name || b.firstname + ' ' + b.middlename + ' ' + b.lastname;
        return (a_name.toUpperCase() > b_name.toUpperCase()) ? 1 : ((b_name.toUpperCase() > a_name.toUpperCase()) ? -1 : 0)
      })
    },
    UPDATE_SIDEBAR(state, data) {
      // console.log('attendees:mutations:UPDATE_SIDEBAR', data)
      state.sidebarShow = data.show
      state.sidebarData = data.data
      state.sidebarChat = data.chat
      state.sidebarCall = data.call
      state.sidebarMeeting = data.meeting
    }
  },
  actions: {
    clear({commit}) {
      commit('UPDATE_ATTENDEES', [])
    },
    showSidebar({commit}, speakerData) {
      // console.log('showSidebar', speakerData);
      commit('UPDATE_SIDEBAR', speakerData)
    },
    hideSidebar({commit}) {
      // console.log('hideSidebar');
      commit('UPDATE_SIDEBAR', {show: false, data: {}, chat: false, call: false, meeting: false})
    },
    getAttendees({commit, state, rootState}, eventSlug) {
      // console.log('eventSlug', eventSlug)
      if (eventSlug) {
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
      } 
      return new Promise(async (resolve, reject) => {
        try {
          // let slug = eventSlug || rootState.event.eventSlug || (apiConfig.hostname === 'localhost' ? '3kpuQuZV293IeL1iVKiuEh' : apiConfig.hostname)
          let slug = eventSlug || rootState.event.eventSlug || apiConfig.hostname
          // const response = await api.get(`/events/list/${apiConfig.hostname}`);
          const response = await api.get(`/events/ds/${slug}`);
          if (response.data) {
            commit('UPDATE_ATTENDEES', response.data)
            resolve(response.data)
          } else {
            commit('UPDATE_ATTENDEES', [])
            resolve([])
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  },
}
