import Vue from 'vue'
// import * as Sentry from "@sentry/vue";
const isProduction = (window.location.hostname === 'localhost' ? false : true)
export default {
    namespaced: false,
    state: {
        socket: null,
        isConnected: false,
        message: '',
        reconnectError: false,
        channels: {}
    },
    mutations:{
      SOCKET_ONOPEN (state, event)  {
        // console.log("SOCKET_ONOPEN", event, );
        Vue.prototype.$socket = event.currentTarget
        state.socket = event.currentTarget
        state.isConnected = true
        this.dispatch('joinEventChannel')
      },
      SOCKET_ONCLOSE (state, event)  {
        // console.log("SOCKET_ONCLOSE", event);
        state.isConnected = false
      },
      SOCKET_ONERROR (state, event)  {
        // console.log("SOCKET_ONERROR", event);
        console.error(state, event)
      },
      // default handler called for all methods
      SOCKET_ONMESSAGE (state, message)  {
        // console.log("SOCKET_ONMESSAGE", message);
        if (Array.isArray(message)) {
          this.dispatch('messages/updateMessages', message)
        } else if (message.type === 'system' && message.content === 'refresh-all') {
          this.dispatch('event/getEvent')
          this.dispatch("navigation/getNavigation");
          this.dispatch('attendees/getAttendees')
          this.dispatch('sessions/getSessions')
          this.dispatch('partners/getPartners')
          this.dispatch('exhibitors/getExhibitors')
        } else if (message.type === 'system' && message.content === 'refresh-event') {
          this.dispatch('event/getEvent')
        } else if (message.type === 'system' && message.content === 'refresh-navigation') {
          this.dispatch("navigation/getNavigation");
        } else if (message.type === 'system' && message.content === 'refresh-attendees') {
          this.dispatch('attendees/getAttendees')
        } else if (message.type === 'system' && message.content === 'refresh-sessions') {
          this.dispatch('sessions/getSessions')
        } else if (message.type === 'system' && message.content === 'refresh-partners') {
          this.dispatch('partners/getPartners')
        } else if (message.type === 'system' && message.content === 'refresh-exhibitors') {
          this.dispatch('exhibitors/getExhibitors')
        } else if (message.type === 'system' && message.content === 'refresh-messages') {
          this.dispatch('messages/clearMessages')
          this.dispatch('joinChannel', message.channelId)
        } else if (message.type === 'system' && message.content === 'reload-app') {
            window.location.reload()
        } else if (message.type === 'text') {
          // let _channels = state.channels
          // if (!_channels[message.channelId]) {
          //   _channels[message.channelId] = []
          // }
          // _channels[message.channelId].push(message)
          // state.channels = _channels
          this.dispatch('messages/pushMessage', message)

          // show notification if required
          this.dispatch('notifications/newMessageReceived', message)
        } else {
          this.dispatch('messages/pushMessage', message)
        }

      },
      // mutations for reconnect methods
      SOCKET_RECONNECT(state, count) {
        // console.log("SOCKET_ONRECONNECT", count);
        // console.info(state, count)
      },
      SOCKET_RECONNECT_ERROR(state) {
        // console.log("SOCKET_ONRECONNECT_ERROR", state);
        state.reconnectError = true;
      },
    },
    actions: {
      joinEventChannel({dispatch, state, rootState}) {
        // console.log('join channel socket', rootState )
        dispatch('messages/clearMessages')
        if (rootState.event.event.communityChannelId && rootState.account.user.bookingNumber) {
          state.socket.sendObj({"service": "channel", "action": "join", "channelId": rootState.event.event.communityChannelId, "communityId": rootState.event.event.communityId, "userId": rootState.account.user.bookingNumber, "name": rootState.account.user.name, "firstname": rootState.account.user.firstname, "middlename": rootState.account.user.middlename, "lastname": rootState.account.user.lastname, email: rootState.account.user.email, mobile: rootState.account.user.mobile})
        } else {
          // console.log('user not logged in. not joining channel')
        }
      },
      joinChannel({dispatch, state, rootState}, channelId) {
        // console.log('join channel socket', rootState )
        if (channelId && rootState.account.user.bookingNumber) {
          const message = {"service": "channel", "action": "join", "channelId": channelId, "communityId": rootState.event.event.communityId, "userId": rootState.account.user.bookingNumber, "name": rootState.account.user.name, "firstname": rootState.account.user.firstname, "middlename": rootState.account.user.middlename, "lastname": rootState.account.user.lastname, email: rootState.account.user.email, mobile: rootState.account.user.mobile}
          if (state.socket.sendObj && typeof state.socket.sendObj === 'function') {
            // console.log('state.socket.sendObj')
            try {
              state.socket.sendObj(message)
            } catch (error) {
              if (isProduction) {
                // Sentry.captureException(error)
              } else {
                console.error(error);
              }
            }
          } else if (state.socket.send && typeof state.socket.send === 'function') {
            // try sending via send
            // console.log('state.socket.send')
            try {
              state.socket.send(JSON.stringify(message))
            } catch (error) {
              if (isProduction) {
                // Sentry.captureException(error)
              } else {
                console.error(error);
              }
            }
          } else {
            // console.log('state.socket.sendObj will retry')
            // if neither works, try after a second
            if (isProduction) {
              // Sentry.captureMessage("join channel retry for channelId " + message.channelId + " for " + rootState.account.user.bookingNumber)
            } else {
              console.error("join channel retry for channelId " + message.channelId + " for " + rootState.account.user.bookingNumber);
            }
            const intervalId = setInterval(() => {
              dispatch('joinChannel', message.channelId)
              clearInterval(intervalId);
            }, 1000);
          }
        } else {
          // console.log('user not logged in. not joining channel')
        }
      },
      wsSendMessage: function({state, rootState}, payload) {
        if (!payload.channelId || payload.channelId.length === 0) {
          payload.channelId = rootState.event.event.communityChannelId;
        }
        state.socket.sendObj({"service":"message", "action": "send", "channelId": payload.channelId, "communityId": rootState.event.event.communityId, "fromRole": rootState.account.roles.type, "userId": rootState.account.user.bookingNumber, "fromId": rootState.account.user.bookingNumber, "fromName": `${rootState.account.user.name}`, "type": "text", "content": payload.message })
      },
      wsSendConversation: function({state, rootState}, payload) {
        if (!payload.channelId || payload.channelId.length === 0) {
          payload.channelId = rootState.event.event.communityChannelId;
        }
        state.socket.sendObj({"service": "message", "action": "conversation", "channelId": rootState.event.communityChannelId, "communityId": rootState.event.communityId, "conversationId": payload.conversationId, "fromRole": rootState.account.roles.type, "userId": rootState.account.user.bookingNumber, "fromId": rootState.account.user.bookingNumber, "fromName": `${rootState.account.user.name}`, "toId": payload.toId || '', "toName": payload.toName || '', "type": "text", "content": payload.message })
      },
      wsSendQuestion: function({state, rootState}, payload) {
        if (!payload.channelId || payload.channelId.length === 0) {
          payload.channelId = rootState.event.event.communityChannelId;
        }
        state.socket.sendObj({"service":"message", "action": "send", "channelId": payload.channelId, "communityId": rootState.event.event.communityId, "fromRole": rootState.account.roles.type, "userId": rootState.account.user.bookingNumber, "fromId": rootState.account.user.bookingNumber, "fromName": `${rootState.account.user.name}`, "type": "question", "content": payload.message })
      },
      wsSendPollResponse: function({state, rootState}, payload) {
        // console.log('wsSendPollResponse', payload);
        if (!payload.channelId || payload.channelId.length === 0) {
          payload.channelId = rootState.event.event.communityChannelId;
        }
        state.socket.sendObj({"service":"message", "action": "send", "channelId": payload.channelId, "communityId": rootState.event.event.communityId, "fromRole": rootState.account.roles.type, "userId": rootState.account.user.bookingNumber, "fromId": rootState.account.user.bookingNumber, "fromName": `${rootState.account.user.name}`, "type": "poll", "content": payload.response, "pollId": payload.pollId })
      },
    }
  }