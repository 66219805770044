import Vue from 'vue'
import getBrowserFingerprint from 'get-browser-fingerprint';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import api from "./api/api";

export default {
  namespaced: true,
  state: {
    token: '',
    tokenCreated: '',
    tokenTTL: 0,
    ability: null,
    user: {},
    roles: {},
  },
  getters: {
    getAuthToken: state => {
      return state.token;
    },
    getDefaultRoute: state => {
      return state.user.navigations.defaultNavigation
    },
    getHomeRouteForLoggedInUser: state => {
      return { name: 'reception' }
    },
    getCompanies: state => {
      return state.user.companies
    },
    getRoles: state => {
      return state.roles
    },
    getId: state => {
      return state.user.bookingId
    },
    isUserLoggedIn: state => {
      // console.log('isUserLoggedIn', state.user.bookingId, state.token.length, state.token.length > 0)
      // console.log('isUserLoggedIn', state.user.bookingId && state.token.length > 0)
      return state.user.bookingId && state.token.length > 0;
    },
    ability: state => {
      return state.ability
    },
    getUserData: state => {
      if (state.user && state.user.name) {
        return state.user
      } else {
        const user = localStorage.getItem("user");
        if (user) {
          const userData = JSON.parse(user)
          return userData
        } else {
          return {}
        }
      }
    },
    requestProfileUpdate: state => {
      if (!state.user.isUpdated || state.user.isUpdated) {
        return true
      }
      return false
    }
  },
  mutations: {
    UPDATE_AUTH_TOKEN(state, value) {
      state.token = value.id
      state.tokenCreated = value.created
      state.tokenTTL = value.ttl
    },
    REMOVE_AUTH_TOKEN(state) {
      state.token = ''
      state.tokenCreated = ''
      state.tokenTTL = ''
    },
    UPDATE_USER(state, value) {
      state.user = value
    },
    UPDATE_USER_AVATAR(state, value) {
      state.user.avatar = value
    },
    UPDATE_ROLES(state, value) {
      state.roles = value
    },
    UPDATE_ABILITY(state, value) {
      state.ability = value
    }
  },
  actions: {
    login({commit}, credentials) {
      return new Promise(async (resolve, reject) => {
        try {
          const fingerprint = getBrowserFingerprint({enableWebgl: true, debug: false});
          // console.log(fingerprint);
          const fpPromise = FingerprintJS.load();
          // Get the visitor identifier when you need it.
          const fp = await fpPromise
          const result = await fp.get()

          // This is the visitor identifier:
          const visitorId = result.visitorId
          // console.log(visitorId)
          credentials.f = [visitorId, fingerprint];

          // Check if a registration with the email address exists
          try {
            const bookingResponse = await api.get(`/bookings/r/${credentials.eventId}/${credentials.email}`)
            if (bookingResponse.status === 404) {
              // Registration not found
              commit('UPDATE_USER', {email: credentials.email, requiresRegistration: true}) 
            } else if (bookingResponse.data) {
              if (!bookingResponse.data.avatar) {
                bookingResponse.data.avatar = ''      
              }
              if (!bookingResponse.data.name) {
                bookingResponse.data.name = bookingResponse.data.firstname + (bookingResponse.data.middlename ? ' ' + bookingResponse.data.middlename : '') + ' ' + bookingResponse.data.lastname
              }
              bookingResponse.data.name = bookingResponse.data.name.replace('undefined', '')
              localStorage.setItem("user", JSON.stringify(bookingResponse.data));
              commit('UPDATE_AUTH_TOKEN', {id: bookingResponse.data.bookingNumber.replace('reg_', ''), created: new Date(), ttl: 86400})
              commit('UPDATE_ROLES', bookingResponse.data.ticket)
              commit('UPDATE_USER', bookingResponse.data)
              commit('UPDATE_ABILITY', [{ action: 'manage', subject: 'all', }])
            }
            resolve(bookingResponse.data)
          } catch (error) {
            // console.error('getBooking', error)
            commit('UPDATE_ABILITY', null)
            reject(error)
          }
        } catch (error) {
          commit('UPDATE_ABILITY', null)
          reject(error)
        }
      })
    },
    logout({commit}) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('hmze')
      commit('REMOVE_AUTH_TOKEN')
      commit('UPDATE_USER', {})
      commit('UPDATE_ROLES', {})
    },
    uploadProfilePicture({commit, state, rootState}, file) {
      return new Promise(async (resolve, reject) => {
        try {
          const data = new FormData()
          data.append("folder", `events/${rootState.event.eventSlug}/avatars`);
          data.append("options", `{"acl":"public-read"}`)
          data.append("file", file);
          const response = await api.patch(`/bookings/${state.user.bookingId}/avatar`, data)
          if (response.data) {
            commit('UPDATE_USER_AVATAR', response.data.url)
          }
          Vue.$toast({
            component: ToastificationContent,
            position: "bottom-left",
            props: {
              title: `Profile Updated`,
              icon: "UserCheckIcon",
              variant: "success",
              text: `Your profile picture has been successfully updated.`,
            },
          });

          resolve(response.data)
        } catch (error) {
          reject(error)
        }
      })
    },
    updateProfile({commit, state}, profile) {
      return new Promise(async (resolve, reject) => {
        try {
          // const response = await api.get(`/events/list/${apiConfig.hostname}`);
          profile.isUpdated = true// set to true so as not to prompt to update
          const response = await api.patch(`/bookings/up`, profile);
          if (response.data) {
            // console.log(response.data)
            commit('UPDATE_USER', response.data)
            Vue.$toast({
              component: ToastificationContent,
              position: "bottom-left",
              props: {
                title: `Profile Updated`,
                icon: "UserCheckIcon",
                variant: "success",
                text: `Your profile has been successfully updated.`,
              },
            });
            resolve(response.data)
          } else {
            commit('UPDATE_USER', {})
            resolve({})
          }
        } catch (error) {
          reject(error)
        }
      })
    },
  },
}
