import dayjs from "../dayjs";

export default {
  install(Vue, options) {
    Vue.prototype.$date = dayjs;
    // Vue.prototype.$date.prototype = dayjs.prototype;
    Vue.prototype.$date.prototype.prettyDate = function(date) {
      date = dayjs(date);
      const diff = this.diff(date, "day");
      if (!diff) {
        return this.format("h mm A");
      } else if (diff === 1) {
        return `Yesterday, ${this.format("h mm A")}`;
      } else {
        return this.format("MMMM DD, YYYY h  mm A");
      }
    };
  }
};