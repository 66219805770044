import Vue from 'vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    all: state => {
      return state.notifications
    },
    byCategory: state => (category) => {
      return state.notifications.filter(notification => notification.category === category)
    },
  },
  mutations: {
    UPDATE_NOTIFICATIONS(state, value) {
      state.notifications = value
    },
    ADD_NOTIFICATION(state, value) {
      state.notifications = [...state.notifications, value]
    }
  },
  actions: {
    newMessageReceived({commit, state, rootState}, message) {
      // Show toast if this is a direct message and not to a public channel
      if (message.fromId && message.toId && (message.fromId === rootState.account.user.bookingNumber || message.toId === rootState.account.user.bookingNumber)) {
        commit('ADD_NOTIFICATION', {type: 'primary', category: 'chat', icon: 'MessageCircleIcon', title: 'New message received', subTitle: `You have a new message from ${message.fromName}`})
        Vue.$toast({
          component: ToastificationContent,
          position: "bottom-left",
          props: {
            title: `New message received`,
            icon: "MessageCircleIcon",
            variant: "primary",
            text: `You have a new chat message from ${message.fromName}`,
          },
        })
      }
    },
  },
}
